import ErrorBoundary from "common/components/ErrorBoundary";
import AuthContextProvider from "common/contexts/AuthContext";
import CartContextProvider from "common/contexts/CartContext";
import UserContextProvider from "common/contexts/UserContext";
import { lazy, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import Signup from "./Signup";
import TermsAndConditions from "./TermsAndConditions";

const AppLayout = lazy(() => import("common/components/AppLayout"));
const Dashboard = lazy(() => import("./Dashboard"));
const RetailPlaceOrder = lazy(() => import("./RetailPlaceOrder"));
const PlaceOrder = lazy(() => import("./PlaceOrder"));
const Cart = lazy(() => import("./NewCart"));
const AllOrders = lazy(() => import("./AllOrders"));
const Suppliers = lazy(() => import("./Suppliers"));
const UserGroups = lazy(() => import("./UserGroups"));
const Customers = lazy(() => import("./Customer"));
const Representatives = lazy(() => import("./Representatives"));
const Attendance = lazy(() => import("./Attendance"));
const Payments = lazy(() => import("./Payments"));
const Profile = lazy(() => import("./Profile"));
const InvoiceManagement = lazy(() => import("./InvoiceManagement"));
const Inventory = lazy(() => import("./Inventory"));
const LedgerOrder = lazy(() => import("./LedgerOrder"));
const Support = lazy(() => import("./Support"));
const Coupons = lazy(() => import("./Coupons"));
const Offers = lazy(() => import("./Offers"));
const Notifications = lazy(() => import("./Notifications"));
const Subscriptions = lazy(() => import("./Subscriptions"));
const UpdateInvoice = lazy(() => import("./UpdateInvoice"));
const PaymentLanding = lazy(() => import("./PaymentLanding"));
const Broadcasts = lazy(() => import("./Broadcasts"));
const Quotations = lazy(() => import("./Quotations/Quotations"));
const Settings = lazy(() => import("./Setting"));
const LandingPage = lazy(() => import("./LandingPage"));
const Ledgers = lazy(() => import("./Ledgers"));
const UpdatesPage = lazy(() => import("./LandingPage/Updates"));
const RetailCart = lazy(() => import("./RetailCart"));
const CreditConsent = lazy(() => import("./CreditConsent"));
const Tracking = lazy(() => import("./Tracking"));
const Logistics = lazy(() => import("./Logistics"));
const DrugInfo = lazy(() => import("./DrugInfo"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});

function App() {
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <CartContextProvider>
          <UserContextProvider>
            <BrowserRouter>
              <ErrorBoundary>
                <Suspense fallback={<div>Loading your content...</div>}>
                  <Routes>
                    <Route path="/" element={<AppLayout />}>
                      <Route index element={<Dashboard />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="suppliers/*" element={<Suppliers />} />
                      <Route path="groups/*" element={<UserGroups />} />
                      <Route path="customers/*" element={<Customers />} />
                      <Route path="ledgers" element={<Ledgers />} />
                      <Route
                        path="representatives/*"
                        element={<Representatives />}
                      />
                      <Route path="attendance" element={<Attendance />} />
                      <Route path="quotations" element={<Quotations />} />
                      <Route
                        path="retailPlaceOrder/*"
                        element={<RetailPlaceOrder />}
                      />
                      <Route path="placeOrder/*" element={<PlaceOrder />} />
                      <Route path="cart" element={<Cart />} />
                      <Route path="retailCart" element={<RetailCart />} />
                      <Route path="orders/*" element={<AllOrders />} />
                      <Route
                        path="invoices/*"
                        element={<InvoiceManagement />}
                      />
                      <Route path="payments/*" element={<Payments />} />
                      <Route path="credit" element={<CreditConsent />} />
                      <Route path="profile" element={<Profile />} />
                      <Route path="inventory" element={<Inventory />} />
                      <Route path="drugInfo" element={<DrugInfo />} />
                      <Route path="ledgerOrder/*" element={<LedgerOrder />} />
                      <Route path="support" element={<Support />} />
                      <Route path="Coupons" element={<Coupons />} />
                      <Route path="offers" element={<Offers />} />
                      <Route path="notifications" element={<Notifications />} />
                      <Route path="subscriptions" element={<Subscriptions />} />
                      <Route path="settings" element={<Settings />} />
                      <Route path="broadcasts" element={<Broadcasts />} />
                      <Route path="tracking" element={<Tracking />} />
                      <Route path="logistics" element={<Logistics />} />

                      <Route path="*" element={<Dashboard />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/reset" element={<ResetPassword />} />
                    <Route path="/terms" element={<TermsAndConditions />} />
                    <Route path="/home" element={<LandingPage />} />
                    <Route path="/updates" element={<UpdatesPage />} />
                    <Route
                      path="/payment-landing"
                      element={<PaymentLanding />}
                    />
                    <Route
                      path="/updateInvoice/:customerId/:updateId"
                      element={<UpdateInvoice />}
                    />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </UserContextProvider>
        </CartContextProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

export default App;
